const dispatchEvent = (
  name: string,
  element: HTMLElement | Document,
  options = {}
) => {
  element.dispatchEvent(
    new CustomEvent(name, {
      bubbles: true,
      ...options,
    })
  );
};

export default dispatchEvent;
